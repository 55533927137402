import {
  AnimatePresence,
  motion,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { useLenis } from "lenis/react";
import { useRef, useState } from "react";
import External from "../global/icons/External";
import Rejouice from "../global/icons/Rejouice";
import { P1 } from "../global/Text";

const TitleAnim = ({ title }) => {
  return (
    <AnimatePresence>
      {title.split("<br/>").map((line, lineIndex) => (
        <div key={lineIndex} className="overflow-hidden">
          {line.split("").map((char, charIndex) => (
            <motion.span
              key={`${lineIndex}-${charIndex}`}
              className="inline-block"
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              transition={{
                delay:
                  0.5 +
                  lineIndex * (line.split("").length * 0.04 * 1.4) +
                  0.04 * charIndex +
                  0.04 * lineIndex,
                damping: 10,
                stiffness: 100,
                ease: [0, 0.98, 0.85, 1.01],
                duration: 0.8,
              }}
            >
              {char}
            </motion.span>
          ))}
        </div>
      ))}
    </AnimatePresence>
  );
};

export default function Header({ data: header, currentSection }) {
  const [isBeingCopied, setIsBeingCopied] = useState(false);
  const [isMenuTitleShown, setIsMenuTitleShown] = useState(false);
  const scrollY = useMotionValue(0);

  useLenis(({ scroll }) => {
    scrollY.set(scroll);

    if (scroll > 300) {
      setIsMenuTitleShown(true);
    } else {
      setIsMenuTitleShown(false);
    }
  });

  const containerRef = useRef(null);

  const textRatio = useTransform(scrollY, (scroll) => {
    if (scroll > window.innerHeight) return 0.2;
    // when scroll = 0, textRatio = 1
    // whens scroll = window.innerHeight, textRatio = 0
    const animDuration = window.innerHeight;
    const ratio = 1 - Math.min(scroll / animDuration, 0.8);
    return ratio;
  });

  const position = useTransform(scrollY, (scroll) => {
    if (!containerRef.current) return;

    const distance = containerRef.current.getBoundingClientRect().top;
    const animDuration = window.innerHeight;
    if (scroll > window.innerHeight) return -Math.min(1, 0.8) * distance;
    // when scroll = 0, position = 0
    // when scroll = window.innerHeight, position = -100

    const ratio = -Math.min(scroll / animDuration, 0.8) * distance;
    return ratio;
  });

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsBeingCopied(true);
      setTimeout(() => setIsBeingCopied(false), 2000);
    });
  };

  return (
    <>
      <header className="fixed top-[20px] left-[10px] right-[10px] z-[10] md:top-[1.3vw] md:left-[1.3vw] md:right-[1.3vw] grid grid-cols-12 gap-x-[1.3vw] gap-y-[10vw] mix-blend-difference">
        <div
          className="row-start-2 md:row-start-1 col-span-12 md:col-span-6"
          ref={containerRef}
        >
          <motion.div
            className="transition-all duration-500"
            style={{
              y:
                currentSection === "expertises" || currentSection === "works"
                  ? "-100%"
                  : 0,
            }}
          >
            <motion.h1
              className="hidden md:block font-suisse font-[600] text-[10.56vw] leading-[8.9vw] tracking-[-0.53vw] text-white origin-top-left"
              style={{
                scale: textRatio,
              }}
            >
              <TitleAnim title={header.title} />
            </motion.h1>
          </motion.div>
        </div>
        <AnimatePresence>
          {isMenuTitleShown && (
            <motion.div
              className="md:hidden row-start-1 col-start-1 col-span-3 text-white"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0 } }}
              exit={{ opacity: 0, transition: { duration: 0.2 } }}
            >
              <h1 className="font-suisse font-[600] text-[4vw] leading-[4vw] tracking-[-0.02vw] text-white">
                <TitleAnim title={header.title} />
              </h1>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="row-start-1 col-start-5 md:col-start-7 xl:col-start-9 col-span-3 md:col-span-3 xl:col-span-2">
          <div className="flex flex-col gap-[1.3vw]">
            <AnimatePresence>
              <button
                className="hidden md:block cursor-pointer text-white text-left overflow-y-hidden"
                onClick={() => copyToClipboard(header.email.label)}
              >
                <motion.div
                  className="flex gap-[6px] items-center"
                  initial={{ y: "100%" }}
                  animate={{ y: 0 }}
                  transition={{
                    delay: 0.5,
                    ease: [0, 0.98, 0.85, 1.01],
                    duration: 0.5,
                  }}
                >
                  <External />
                  <div className="h-[18px] overflow-x-hidden">
                    <div
                      className="flex flex-col transition-transform"
                      style={{
                        transform: isBeingCopied
                          ? "translateY(-18px)"
                          : "translateY(0vw)",
                      }}
                    >
                      <p className="font-suisse font-[500] md:font-[400] text-white mix-blend-difference text-[14px] leading-[17px]">
                        <button
                          className={`h-auto inline relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-0 after:w-0 hover:after:w-full after:origin-left after:transition-all ${
                            isBeingCopied
                              ? "after:bg-transparent"
                              : "after:bg-white"
                          }`}
                        >
                          {header.email.label}
                        </button>
                      </p>
                      <P1 as="div">Copied to clipboard!</P1>
                    </div>
                  </div>
                </motion.div>
              </button>
            </AnimatePresence>
            <div>
              <button
                className="w-full md:hidden block cursor-pointer text-white text-left overflow-y-hidden"
                onClick={() => copyToClipboard(header.email.label)}
              >
                <motion.a
                  initial={{ y: "100%" }}
                  animate={{ y: 0 }}
                  transition={{
                    delay: 0.5,
                    ease: [0, 0.98, 0.85, 1.01],
                    duration: 0.5,
                  }}
                  href={`mailto:${header.email.label}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block"
                >
                  <P1 className="text-white ">Email</P1>
                </motion.a>
              </button>
              <AnimatePresence>
                {header.socials.map((social, index) => (
                  <div className="overflow-y-hidden h-[18px]" key={index}>
                    <motion.a
                      initial={{ y: "100%" }}
                      animate={{ y: 0 }}
                      transition={{
                        delay: 0.5 + 0.06 * (index + 1),
                        ease: [0, 0.98, 0.85, 1.01],
                        duration: 0.5,
                      }}
                      key={index}
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block"
                    >
                      <p className="font-suisse font-[500] md:font-[400] text-white mix-blend-difference text-[14px] leading-[17px]">
                        <a
                          className="relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-0 after:w-0 hover:after:w-full after:origin-left after:transition-all"
                          href={social.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {social.name}
                        </a>
                      </p>
                    </motion.a>
                  </div>
                ))}
                <div className="overflow-y-hidden h-[18px]">
                  <motion.button
                    initial={{ y: "100%" }}
                    animate={{ y: 0 }}
                    transition={{
                      delay: 0.5 + 0.06 * (1 + 1),
                      ease: [0, 0.98, 0.85, 1.01],
                      duration: 0.5,
                    }}
                    // target="_blank"
                    // rel="noopener noreferrer"
                    className="block"
                    data-cal-namespace="30min"
                    data-cal-link="guillaume-hamon-q5ym6s/30min"
                    data-cal-config='{"layout":"month_view"}'
                  >
                    <p className="font-suisse font-[500] md:font-[400] text-white mix-blend-difference text-[14px] leading-[17px]">
                      <div className="relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-0 after:w-0 hover:after:w-full after:origin-left after:transition-all">
                        Book a call
                      </div>
                    </p>
                  </motion.button>
                </div>
              </AnimatePresence>
            </div>
          </div>
        </div>
        <div className="col-start-8 md:col-start-10 xl:col-start-11 col-span-5 md:col-span-2">
          <AnimatePresence>
            <div className="flex flex-col">
              {header.role.split("<br/>").map((line) => (
                <div className="overflow-y-hidden h-[17px]">
                  <motion.div
                    initial={{ y: "100%" }}
                    animate={{ y: 0 }}
                    transition={{
                      delay: 0.5,
                      ease: [0, 0.98, 0.85, 1.01],
                      duration: 0.5,
                    }}
                  >
                    <P1 className="text-white">{line}</P1>
                  </motion.div>
                </div>
              ))}
              <div
                className={`mt-[2.6vw] md:mt-[1.3vw] transition-opacity duration-300 ${
                  isMenuTitleShown
                    ? "opacity-0 delay-150"
                    : "opacity-100 delay-0"
                }`}
              >
                {header.description?.split("<br/>").map((line, index) => (
                  <div className="overflow-y-hidden h-[17px]" key={index}>
                    <motion.div
                      initial={{ y: "100%" }}
                      animate={{ y: 0 }}
                      transition={{
                        delay: 0.5 + 0.06 * (index + 1),
                        ease: [0, 0.98, 0.85, 1.01],
                        duration: 0.5,
                      }}
                    >
                      <P1 className="text-white block">{line}</P1>
                    </motion.div>
                  </div>
                ))}
              </div>
              <div
                className={`mt-[2.6vw] md:mt-[1.3vw] transition-opacity duration-300 ${
                  isMenuTitleShown
                    ? "opacity-0 delay-0"
                    : "opacity-100 delay-150"
                }`}
              >
                <div className="h-[17px] overflow-hidden">
                  <motion.div
                    initial={{ y: "100%" }}
                    animate={{ y: 0 }}
                    transition={{
                      delay: 0.8,
                      ease: [0, 0.98, 0.85, 1.01],
                      duration: 0.5,
                    }}
                    className="h-[17px] flex items-end"
                  >
                    <P1 className="text-white">Founder of </P1>
                    <a
                      className="translate-y-[-3.5px] translate-x-[6px] scale-[1.1] h-[14px] w-[50px] inline-block relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-[-2px] after:w-full after:origin-left after:transition-all"
                      href="//rejouice.com"
                      title="Rejouice's website"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Rejouice />
                    </a>
                    <span className="opacity-80">.</span>
                  </motion.div>
                </div>
              </div>
            </div>
          </AnimatePresence>
        </div>
      </header>
      <h1 className="md:hidden font-suisse font-[600] text-[21.21vw] leading-[17.2vw] tracking-[-1.06vw] text-black origin-top-left">
        <TitleAnim title={header.title} />
      </h1>
    </>
  );
}
