import {
  AnimatePresence,
  motion,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { useLenis } from "lenis/react";
import { useEffect, useState } from "react";
import Picture from "../global/Picture";

const RollingPicture = ({ pictures }) => {
  const [currentPicture, setCurrentPicture] = useState(0);

  useLenis(({ scroll }) => {
    // get the second last character of the scroll value
    console.log({ scroll });
    const scrollString = parseInt(scroll).toString();
    const lastCharacter = scrollString[scrollString.length - 3];
    // convert it to a number
    const ten = parseInt(lastCharacter);
    // set the current picture to the last number
    const indexToUse = parseInt((ten / 10) * pictures.length);
    setCurrentPicture(indexToUse);
  });

  return <Picture url={pictures[currentPicture]} />;
};

export default function TV({ data, currentSection, top }) {
  const scrollY = useMotionValue(0);

  useLenis(({ scroll }) => {
    scrollY.set(scroll);
  });

  useEffect(() => {
    // preload the video
    const video = document.createElement("video");
    video.src = data.url;
    video.preload = "auto";
    video.load();

    // preload pictures
    data.pictures.map((picture) => {
      const img = new Image();
      img.src = picture.url;
      return img;
    });
  }, [data.url, data.pictures]);

  const y = useTransform(scrollY, (scroll) => {
    if (scroll > top) {
      return top - scroll;
    } else return 0;
  });

  return (
    <motion.section
      className="hidden md:flex fixed bottom-[1.39vw] left-[1.39vw] w-[23.26vw] flex-col gap-[0.5vw] pointer-events-none"
      style={{ y }}
    >
      {/* <div className="overflow-y-hidden h-[max(12px,1vw)]">
        <AnimatePresence>
          {currentSection === "about" && (
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{
                ease: [0, 0.98, 0.85, 1.01],
                duration: 0.5,
              }}
            >
              <P1 className="block text-black">See the work</P1>
            </motion.div>
          )}
        </AnimatePresence>
      </div> */}
      <div className="aspect-video overflow-hidden">
        <AnimatePresence>
          {(currentSection === "about" || currentSection === "expertises") && (
            <motion.div
              initial={{
                opacity: 0,
                transition: {
                  ease: "easeInOut",
                  duration: 0.5,
                },
              }}
              animate={{ opacity: 1 }}
              exit={{
                opacity: 0,
                transition: {
                  ease: "easeInOut",
                  duration: 0,
                },
              }}
            >
              {currentSection === "about" && (
                <RollingPicture pictures={data.expertisePictures} />
              )}
              {currentSection === "expertises" && (
                <RollingPicture pictures={data.pictures} />
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.section>
  );
}
