import Picture from "../global/Picture";
import { P1 } from "../global/Text";

export default function MobileTV({ data, text }) {
  return (
    <div className="flex md:hidden flex-col gap-[2vw] pb-[14vw] md:pb-[7vw] border-b-[0.5px] border-[#fff]">
      <div className="w-full aspect-video">
        <Picture url={data.url} />
      </div>
      {text && <P1 className="block text-white">{text}</P1>}
    </div>
  );
}
