import { useMotionValue, useTransform } from "framer-motion";
import { useLenis } from "lenis/react";
import { useRef } from "react";
import Picture from "../global/Picture";
import { H2 } from "../global/Text";

const CUT_TITLE_AT = 18;

export default function Works({ data }) {
  const { title, subtitle, sections } = data;

  const containerRef = useRef(null);

  const scrollY = useMotionValue(0);

  useLenis(({ scroll }) => {
    scrollY.set(scroll);
  });

  useTransform(scrollY, (scroll) => {
    if (containerRef.current === null) return;

    if (scroll > containerRef.current.offsetTop - window.innerHeight / 2) {
      document.body.style.backgroundColor = "#000";
    } else {
      document.body.style.backgroundColor = "#fff";
    }

    if (scroll > containerRef.current.offsetTop) {
      document.documentElement.style.setProperty(
        "--works-extra-words-opacity",
        "0"
      );
    } else {
      document.documentElement.style.setProperty(
        "--works-extra-words-opacity",
        "1"
      );
    }
  });

  return (
    <div className="relative my-[13vw] md:my-[6.2vw]" ref={containerRef}>
      <div className="grid grid-cols-12 gap-x-[1.3vw] gap-y-[25vw] mix-blend-difference">
        <div className="lg:absolute col-span-12 md:col-span-2 lg:h-full">
          {title && (
            <>
              <h2 className="hidden md:block sticky top-[1.3vw] font-suisse font-[600] text-white mix-blend-difference text-[6.11vw] md:text-[2.19vw] leading-[5.93vw] md:leading-[2.36vw] tracking-[-0.08vw] md:tracking-[-0.03vw]">
                {title.split("<br/>").map((line, lineIndex) => (
                  <div key={lineIndex}>
                    {line.split("").map((word, wordIndex) => (
                      <span
                        key={`${lineIndex}-${wordIndex}`}
                        className={`${
                          lineIndex * line.length + wordIndex > CUT_TITLE_AT
                            ? "opacity-[var(--works-extra-words-opacity)]"
                            : ""
                        } transition-opacity duration-500`}
                        style={{
                          transitionDelay: `${
                            (title.replace("<br/>", "").split("").length -
                              (lineIndex * line.length + wordIndex)) *
                            0.02
                          }s`,
                        }}
                      >
                        {word}
                      </span>
                    ))}
                  </div>
                ))}
              </h2>
              <H2 className="md:hidden">{title}</H2>
            </>
          )}
        </div>
        <div className="col-start-1 md:col-start-7 xl:col-start-9 col-span-12 md:col-span-6 xl:col-span-4 flex flex-col gap-[6.25vw] pr-[2.5vw]">
          <H2 as="p">{subtitle}</H2>
        </div>
      </div>
      <div className="mt-[13vw]">
        {sections?.map((section, index) => (
          <div className="my-[1.39vw] w-full aspect-[36/19]" key={index}>
            <Picture url={section.picture.url} alt={section.picture.alt} lazy />
          </div>
        ))}
      </div>
    </div>
  );
}
