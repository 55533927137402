export default function Rejouice() {
  return (
    <svg
      version="1.1"
      id="svg2"
      width="50"
      height="16"
      viewBox="0 0 3413.3333 800"
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
    >
      <defs id="defs6" />
      <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,800)">
        <g id="g10" transform="scale(0.1)">
          <path
            d="m 7934.35,5440.68 c -217.25,0 -407.34,-183.31 -407.34,-407.35 0,-230.83 190.09,-414.13 407.34,-414.13 210.46,0 400.56,183.3 400.56,414.13 0,224.04 -190.1,407.35 -400.56,407.35"
            id="path12"
          />
          <path
            d="M 873.711,2528.16 V 654.371 H 1471.15 V 2460.27 c 0,814.69 373.4,1181.3 964.05,1181.3 h 264.77 v 577.07 c -108.62,20.37 -190.09,33.95 -319.09,33.95 -522.75,0 -1507.169,-393.77 -1507.169,-1724.43"
            id="path14"
          />
          <path
            d="m 4587.34,3729.83 c 514.06,0 963.46,-280.15 1036.28,-984.42 H 3512.04 c 90.6,698.03 518.1,984.42 1075.3,984.42 z m 13.57,549.91 c -984.41,13.58 -1724.42,-692.48 -1724.42,-1853.42 0,-1249.19 801.11,-1866.988 1744.79,-1866.988 896.16,0 1493.6,502.388 1615.8,1215.238 h -611.02 c -115.41,-414.13 -441.29,-665.33 -991.2,-665.33 -583.47,0 -1076.05,340.6 -1135.47,1120.2 h 2737.69 c 13.58,101.84 20.37,196.88 20.37,298.72 0,869 -522.76,1738 -1656.54,1751.58"
            id="path16"
          />
          <path
            d="m 7649.21,2378.8 c 0,-814.69 -373.4,-1181.3 -964.05,-1181.3 H 6420.39 V 620.43 c 108.62,-20.371 190.09,-33.95 319.08,-33.95 522.76,0 1507.18,393.77 1507.18,1724.43 V 4184.69 H 7649.21 V 2378.8"
            id="path18"
          />
          <path
            d="m 16794.1,5440.68 c -217.2,0 -407.3,-183.31 -407.3,-407.35 0,-230.83 190.1,-414.13 407.3,-414.13 210.5,0 400.6,183.3 400.6,414.13 0,224.04 -190.1,407.35 -400.6,407.35"
            id="path20"
          />
          <path
            d="m 19340,3768.95 c 294.2,0 536.4,-67.87 726.5,-203.69 190.1,-135.83 303.1,-357.57 339.4,-665.31 h 604.3 c -31.7,461.62 -200.3,813.47 -505.8,1055.65 -305.5,242.09 -689.1,363.27 -1150.8,363.27 -316.9,0 -607.6,-73.67 -872.4,-220.63 -264.8,-147.15 -476.4,-361.06 -634.8,-641.64 -158.5,-280.67 -237.6,-610.98 -237.6,-991.19 0,-384.73 78.1,-717.43 234.2,-998.01 156.2,-280.67 366.6,-495.591 631.4,-644.951 264.8,-149.359 555.6,-224.039 872.4,-224.039 298.7,0 569.2,60.039 811.3,179.93 242.1,119.801 439,294.12 590.7,522.76 151.6,228.46 238.7,501.21 261.4,818.07 h -604.3 c -40.7,-316.86 -159.5,-557.84 -356.4,-723.04 -196.9,-165.29 -435.7,-247.8 -716.3,-247.8 -316.8,0 -580.5,113.08 -790.9,339.42 -210.5,226.25 -315.7,549.93 -315.7,970.84 0,429.94 104.1,755.83 312.3,977.66 208.1,221.73 475.3,332.7 801.1,332.7"
            id="path22"
          />
          <path
            d="m 23056.2,3729.83 c 514,0 963.4,-280.15 1036.3,-984.42 h -2111.6 c 90.6,698.03 518.1,984.42 1075.3,984.42 z m 1670.1,-1201.67 c 0,869 -522.8,1738 -1656.5,1751.58 -984.5,13.58 -1724.5,-692.48 -1724.5,-1853.42 0,-1249.19 801.1,-1866.988 1744.8,-1866.988 896.2,0 1493.6,502.388 1615.8,1215.238 h -611 c -115.4,-414.13 -441.3,-665.33 -991.2,-665.33 -583.5,0 -1076,340.6 -1135.5,1120.2 h 2737.7 c 13.6,101.84 20.4,196.88 20.4,298.72"
            id="path24"
          />
          <path
            d="m 16495.4,654.371 h 597.4 V 4184.69 h -597.4 V 654.371"
            id="path26"
          />
          <path
            d="m 10514.2,1109.24 c -611.02,0 -1133.77,434.5 -1133.77,1310.29 0,909.74 522.75,1310.3 1133.77,1310.3 611,0 1133.8,-400.56 1133.8,-1310.3 0,-875.79 -522.8,-1310.29 -1133.8,-1310.29 z m 0,3170.5 c -936.89,0 -1751.58,-672.12 -1751.58,-1853.42 0,-1188.09 814.69,-1866.988 1751.58,-1866.988 936.9,0 1751.6,685.688 1751.6,1866.988 0,1188.09 -814.7,1853.42 -1751.6,1853.42"
            id="path28"
          />
          <path
            d="m 15225.8,2059.71 c 0,-746.8 -468.4,-957.26 -916.5,-957.26 -448.1,0 -930.1,210.46 -930.1,957.26 v 2124.98 h -597.4 V 2025.77 c 0,-923.32 631.3,-1466.438 1527.5,-1466.438 896.2,0 1514,543.118 1514,1466.438 v 2158.92 h -597.5 V 2059.71"
            id="path30"
          />
        </g>
      </g>
    </svg>
  );
}
