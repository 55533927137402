import { getCalApi } from "@calcom/embed-react";
import { AnimatePresence, motion } from "framer-motion";
import { useLenis } from "lenis/react";
import { useEffect, useRef, useState } from "react";
import { P1 } from "../global/Text";
import Rejouice from "../global/icons/Rejouice";

export default function Footer({ data: footer }) {
  const [isBeingCopied, setIsBeingCopied] = useState(false);
  const [isContactMeVisible, setIsContactMeVisible] = useState(false);

  const footerBottom = useRef(null);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsBeingCopied(true);
      setTimeout(() => setIsBeingCopied(false), 2000);
    });
  };

  useLenis(({ scroll }) => {
    // total scrollPageYOffset
    const total = document.body.offsetHeight - window.innerHeight;
    if (scroll >= total - 50) {
      setIsContactMeVisible(true);
    } else {
      setIsContactMeVisible(false);
    }
  });

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: "30min" });
      cal("ui", {
        styles: { branding: { brandColor: "#000000" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    <footer className="grid grid-cols-12 gap-[1.3vw] mt-[20dvh] lg:mt-[10dvh] h-[80dvh] md:mt-0 md:h-[90dvh]">
      <h2 className="hidden md:block fixed top-[1.3vw] left-[1.3vw] font-suisse font-[600] text-white mix-blend-difference text-[6.11vw] md:text-[2.19vw] leading-[5.93vw] md:leading-[2.36vw] tracking-[-0.08vw] md:tracking-[-0.03vw]">
        <AnimatePresence>
          {isContactMeVisible && (
            <>
              {`Contact me`.split(" ").map((line, lineIndex) => (
                <div
                  key={lineIndex}
                  className="inline-block overflow-hidden mr-[7px]"
                >
                  {line.split("").map((char, charIndex) => (
                    <motion.span
                      key={`${lineIndex}-${charIndex}`}
                      className="inline-block"
                      initial={{ y: "100%" }}
                      animate={{ y: 0 }}
                      transition={{
                        delay:
                          0.5 +
                          lineIndex * (line.split("").length * 0.04 * 1.4) +
                          0.04 * charIndex +
                          0.04 * lineIndex,
                        damping: 10,
                        stiffness: 100,
                        ease: [0, 0.98, 0.85, 1.01],
                        duration: 0.8,
                      }}
                    >
                      {char}
                    </motion.span>
                  ))}
                </div>
              ))}
            </>
          )}
        </AnimatePresence>
      </h2>
      <div className="row-start-1 md:row-start-2 col-span-8 flex flex-col justify-end">
        <P1>{footer.copyright}</P1>
      </div>
      {/* <div className="md:hidden row-start-1 col-span-4 flex flex-col justify-end">
        <div className="h-[20px] flex items-end translate-y-[2px] translate-x-[12px]">
          <span className="opacity-80 text-white">visit </span>
          <a
            className="translate-y-[-5.5px] md:translate-y-[-2.5px] translate-x-[8px] md:translate-x-[6px] scale-[1.2] md:scale-[1.1] h-[14px] w-[50px] inline-block relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-[-2px] after:w-0 hover:after:w-full after:origin-left after:transition-all"
            href="//rejouice.com"
            title="Rejouice's website"
            target="_blank"
            rel="noreferrer"
          >
            <Rejouice />
          </a>
          <span className="opacity-80">.</span>
        </div>
      </div> */}
      <div className="hidden col-start-5 xl:col-start-9 col-span-8 md:col-span-6 grid-cols-5 md:grid-cols-2 gap-[1.3vw] pt-[1vw] border-t-[0.5px] border-[#fff] mix-blend-difference">
        <div className="col-span-2 md:col-span-1">
          <P1>{footer.title}</P1>
        </div>
        <div className="col-span-3 md:col-span-1">
          <div className="flex flex-col gap-[1.3vw]">
            <p className="hidden md:block font-suisse font-[500] md:font-[400] text-white mix-blend-difference text-[14px] leading-[17px] tracking-[-0.05px]">
              <div className="h-[20px] flex items-end">
                <span className="opacity-80 mr-[4px]">Connect on </span>
                <a
                  className="relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-0 after:w-0 hover:after:w-full after:origin-left after:transition-all"
                  href="/"
                  title="Guillaume Hamon's LinkedIn"
                >
                  LinkedIn
                </a>
                <span className="opacity-80">,</span>
              </div>
              <div className="h-[20px] flex items-end">
                <button
                  className="relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-0 after:w-0 hover:after:w-full after:origin-left after:transition-all"
                  // href="https://calendly.com/guillaume-hamon"
                  // target="_blank"
                  title="Book a call with Guillaume Hamon"
                  // rel="noreferrer"
                  data-cal-namespace="30min"
                  data-cal-link="guillaume-hamon-q5ym6s/30min"
                  data-cal-config='{"layout":"month_view"}'
                >
                  book a call
                </button>
                <span className="opacity-80 mr-[4px]">, </span>
                <a
                  className="relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-0 after:w-0 hover:after:w-full after:origin-left after:transition-all"
                  href="mailto:hello@guillaume-hamon.com"
                  title="Guillaume Hamon's LinkedIn"
                  onClick={(e) => {
                    e.preventDefault();
                    copyToClipboard("hello@guillaume-hamon.com");
                  }}
                >
                  email me
                </a>
                <span className="opacity-80">, </span>
              </div>
              <div className="h-[20px] flex items-end">
                <span className="opacity-80">or visit </span>
                <a
                  className="translate-y-[-2.5px] translate-x-[6px] scale-[1.1] h-[14px] w-[50px] inline-block relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-[-2px] after:w-0 hover:after:w-full after:origin-left after:transition-all"
                  href="//rejouice.com"
                  title="Rejouice's website"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Rejouice />
                </a>
                <span className="opacity-80">.</span>
              </div>
            </p>
            <p className="md:hidden font-suisse font-[500] md:font-[400] text-white mix-blend-difference text-[14px] leading-[17px] tracking-[-0.05px]">
              <div className="h-[20px] flex items-end">
                <button
                  className="relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-0 after:w-0 hover:after:w-full after:origin-left after:transition-all"
                  // href="https://calendly.com/guillaume-hamon"
                  // target="_blank"
                  title="Guillaume Hamon's LinkedIn"
                  // rel="noreferrer"
                  data-cal-namespace="30min"
                  data-cal-link="guillaume-hamon-q5ym6s/30min"
                  data-cal-config='{"layout":"month_view"}'
                >
                  Book a call
                </button>
                <span className="opacity-80 mr-[4px]">, </span>
              </div>
              <div className="h-[20px] flex items-end">
                <a
                  className="relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-0 after:w-0 hover:after:w-full after:origin-left after:transition-all"
                  href="mailto:hello@guillaume-hamon.com"
                  title="Guillaume Hamon's LinkedIn"
                  onClick={(e) => {
                    e.preventDefault();
                    copyToClipboard("hello@guillaume-hamon.com");
                  }}
                >
                  email me
                </a>
                <span className="opacity-80">, </span>
              </div>
              <div className="h-[20px] flex items-end">
                {/* <span className="opacity-80">or visit </span>
                <a
                  className="translate-y-[-3.5px] md:translate-y-[-2.5px] translate-x-[8px] md:translate-x-[6px] scale-[1.2] md:scale-[1.1] h-[14px] w-[50px] inline-block relative after:absolute after:content-[''] after:h-[1px] after:bg-white after:left-0 after:bottom-[-2px] after:w-0 hover:after:w-full after:origin-left after:transition-all"
                  href="//rejouice.com"
                  title="Rejouice's website"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Rejouice />
                </a>
                <span className="opacity-80">.</span> */}
              </div>
            </p>
            {isBeingCopied && <P1>Copied to clipboard!</P1>}
          </div>
        </div>
      </div>
      <div className="hidden md:block h-[1px]" ref={footerBottom} />
    </footer>
  );
}
