import ReactLenis, { useLenis } from "lenis/react";
import { useRef, useState } from "react";
import "./App.css";
import About from "./components/About";
import BigPicture from "./components/BigPicture";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MobileTV from "./components/MobileTV";
import TV from "./components/TV";
import Works from "./components/Works";
import content from "./content.json";

const { header, picture, about, expertises, works, footer, showreel } = content;

function App() {
  const aboutTop = useRef(null);
  const expertiseTop = useRef(null);
  const worksTop = useRef(null);
  const footerTop = useRef(null);

  const [currentSection, setCurrentSection] = useState("intro");

  useLenis(({ scroll }) => {
    const aboutTopY = aboutTop.current.getBoundingClientRect().top;
    const expertiseTopY = expertiseTop.current.getBoundingClientRect().top;
    const worksTopY = worksTop.current.getBoundingClientRect().top;
    const footerTopY = footerTop.current.getBoundingClientRect().top;

    if (footerTopY < 0) {
      setCurrentSection("footer");
    } else if (worksTopY < 0) {
      setCurrentSection("works");
    } else if (expertiseTopY < window.innerHeight - 400) {
      setCurrentSection("expertises");
    } else if (aboutTopY < window.innerHeight * 0.7) {
      setCurrentSection("about");
    } else {
      setCurrentSection("intro");
    }
  });

  return (
    <ReactLenis root>
      {/* <> */}
      <div className="px-[10px] py-[20px] md:p-[1.39vw]">
        <div className="h-[27vh] md:h-[18vw]"></div>
        <Header data={header} currentSection={currentSection} />
        <div className="mt-[14.6vw]">
          <BigPicture picture={picture} />
          <div className="h-[0px]" ref={aboutTop} />
          <About data={about} />
          <MobileTV data={showreel} />
          <div className="h-[0px]" ref={expertiseTop} />
          <About data={expertises} />
          <MobileTV data={showreel.expertiseShowreel} />
          <div className="h-[0px]" ref={worksTop} />
          <Works data={works} />
        </div>
        <div className="h-[0px]" ref={footerTop} />
        <Footer data={footer} />
      </div>
      <TV
        data={showreel}
        currentSection={currentSection}
        top={
          worksTop.current
            ? worksTop.current.offsetTop - window.innerHeight - 0
            : 0
        }
      />
      {/* <Grid /> */}
      {/* </> */}
    </ReactLenis>
  );
}

export default App;
