export default function BigPicture({ picture }) {
  return (
    <div className="w-full aspect-[373/454] md:aspect-[36/19]">
      <img
        src={picture.url}
        alt={picture.alt}
        className="w-full h-full object-cover"
      />
    </div>
  );
}
