export default function Picture({ url, alt, lazy }) {
  const isVideo = url.endsWith(".webm");

  return isVideo ? (
    <video
      autoPlay={true} // Auto play when fully visible
      loop
      muted="true"
      playsInline
      className="object-cover w-full h-full"
      alt={alt}
      lazy={lazy ? "true" : undefined}
    >
      <source src={url} type="video/webm" />
    </video>
  ) : (
    <img
      lazy={lazy ? "true" : undefined}
      src={url}
      alt={alt}
      className="object-cover w-full h-full"
    />
  );
}
