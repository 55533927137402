import { twMerge } from "tailwind-merge";

export const H1 = ({ as: tag, className = "", children }) => {
  const Tag = tag || "h1";
  return (
    <Tag
      className={twMerge(
        "font-suisse font-[600] text-white mix-blend-difference text-[max(83px,10.56vw)] leading-[max(66px,8.33vw)] tracking-[min(-4,15px,-0.53vw)]",
        className
      )}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

export const H2 = ({ as: tag, className = "", children }) => {
  const Tag = tag || "h2";
  return (
    <Tag
      className={twMerge(
        "font-suisse font-[600] text-white mix-blend-difference text-[6.11vw] md:text-[2.19vw] leading-[5.93vw] md:leading-[2.36vw] tracking-[-0.08vw] md:tracking-[-0.03vw]",
        className
      )}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

export const P1 = ({ as: tag, className = "", children }) => {
  const Tag = tag || "p";
  return (
    <Tag
      className={twMerge(
        "font-suisse font-[500] md:font-[400] text-white mix-blend-difference text-[14px] leading-[17px]",
        className
      )}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};
