export default function External() {
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.42463 1.40754L1.8875 1.41782L1.90401 0.476776L7.02747 0.476776L7.02747 5.60018L6.09782 5.61678L6.09782 2.07971L1.3717 6.81641L0.699015 6.14372L5.42463 1.40754Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
